<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item
                :to="{
                    path: '/pczx_index',
                }"
                >拼词之星管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>房间管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-row :gutter="15">
            <el-col :span="24">
                <el-card style="margin-top: 15px">
                    <el-row class="title_row">
                        <el-col>
                            <span class="title_class">信息筛选</span>
                        </el-col>
                    </el-row>
                    <el-form
                        :model="queryForm"
                        class="filter_Form"
                        label-width="100px"
                        ref="queryFormRef"
                    >
                        <el-row :gutter="10">
                            <el-col :span="8">
                                <el-form-item label="房间编号" prop="code">
                                    <el-input
                                        v-model="queryForm.code"
                                        placeholder="请输入"
                                        clearable
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="房间名称" prop="name">
                                    <el-input
                                        v-model="queryForm.name"
                                        placeholder="请输入"
                                        clearable
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="比赛类型" prop="type">
                                    <el-select
                                        placeholder="请选择"
                                        style="width: 100%"
                                        v-model="queryForm.type"
                                    >
                                        <el-option
                                            v-for="item in dict_gameType"
                                            :key="item.dictValue"
                                            :label="item.dictLabel"
                                            :value="item.dictValue"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <el-row :gutter="20" type="flex" justify="end">
                        <el-button type="primary" @click="search"
                            >查询</el-button
                        >
                        <el-button type="primary" @click="reset"
                            >重置</el-button
                        >
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <!-- 报名信息列表 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row" align="middle" type="flex">
                <el-col :span="9">
                    <span class="title_class">房间信息列表</span>
                </el-col>
                <el-col :span="18" style="text-align: right">
                    <el-button
                        type="primary"
                        @click="exportSignin"
                        icon="el-icon-download"
                        >导出信息</el-button
                    >
                    <el-button
                        type="primary"
                        icon="el-icon-plus"
                        @click="handleToCreate"
                        >新建房间</el-button
                    >
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column label="房间名" prop="name">
                    <template slot-scope="scope">
                        <el-link
                            style="color: #409eff"
                            @click="handleToDetails(scope.row.id)"
                            >{{ scope.row.name }}</el-link
                        >
                    </template>
                </el-table-column>
                <el-table-column label="code码" prop="code">
                    <template slot-scope="scope">
                        <span style="color: red">{{
                            scope.row.code
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="比赛类型"
                    prop="typeStr"
                    :formatter="typeStrFormatter"
                ></el-table-column>
                <el-table-column label="座位数" prop="sitNum"></el-table-column>
                <el-table-column label="时间" prop="startTime">
                  <template slot-scope="scope">
                      <span>{{scope.row.startTime}}-{{scope.row.endTime}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180px">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="small"
                            @click="editRoom(scope.row)"
                            >编辑房间</el-button
                        >
                        <el-button
                            type="text"
                            size="small"
                            @click="handleToDetails(scope.row.id)"
                            >查看详情</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 新建-修改房间 -->
        <el-dialog
            title="编辑房间"
            :visible.sync="editRoomDialogVisible"
            width="40%"
            @close="editRoomDialogClose"
        >
            <el-form
                :model="editRoomForm"
                label-width="80px"
                ref="editRoomFormRef"
                :rules="editRoomFormRules"
            >
                <el-form-item label="房间编号" prop="code">
                    <el-input
                        disabled
                        v-model="editRoomForm.code"
                        placeholder="请输入"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="比赛类型" prop="type">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="editRoomForm.type"
                    >
                        <el-option
                            v-for="item in dict_gameType"
                            :key="item.dictValue"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="房间名称" prop="name">
                    <el-input
                        v-model="editRoomForm.name"
                        placeholder="请输入"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="场次" prop="ampm">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="editRoomForm.ampm"
                    >
                        <el-option
                            v-for="(item, index) in dict_examState"
                            :key="index"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="座位数量" prop="sitNum">
                    <el-input
                        v-model="editRoomForm.sitNum"
                        placeholder="请输入"
                        type="number"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="比赛时间" prop="dateRange">
                    <el-date-picker
                        style="width: 100%"
                        :default-time="['00:00:00', '23:59:59']"
                        v-model="editRoomForm.dateRange"
                        type="datetimerange"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="daterangeChange"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editRoomDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="saveRoomInfo"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
  getPczxRoomList,
  getRoomCode,
  createPczxRoom
} from '@/http/api'
export default {
  data () {
    return {
      editRoomForm: {
        ampm: null,
        code: '',
        name: '',
        type: null,
        dateRange: null,
        state: true,
        sitNum: null,
        season: this.$chnEngStatusCode.defaultSeason
      },
      editRoomDialogVisible: false,
      dict_gameType: this.$userInfo.getDataList(this, 'pczx_match_type').then(value => { this.dict_gameType = value }),
      dict_examState: this.$userInfo.dict_examState(),
      dictExamstatus: this.$userInfo.dictExamstatus(),
      queryForm: {
        pageNum: 1,
        pageSize: 10
      },
      editRoomFormRules: {
        ampm: [{ required: true, message: '请选择', trigger: 'change' }],
        code: [{ required: true, message: '请输入', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        type: [{ required: true, message: '请选择', trigger: 'change' }],
        dateRange: [{ required: true, message: '请选择', trigger: 'change' }],
        state: [{ required: true, message: '请选择', trigger: 'change' }],
        sitNum: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      total: 0,
      dataList: []
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    search () {
      this.getDataList()
    },
    reset () {
      this.queryForm.pageNum = 1
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    getDataList () {
      getPczxRoomList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 导出房间信息
    exportSignin () {
      var downLoadUrl = this.$env.baseIP + 'pczxRoom/excelExport'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, this.queryForm)
    },
    // 新建考场
    handleToCreate () {
      getRoomCode().then((res) => {
        this.editRoomForm.code = res.data
        this.editRoomDialogVisible = true
      }).catch((err) => {
        console.log('err', err)
      })
    },
    daterangeChange (e) {
      if (e.length === 2) {
        this.editRoomForm.startTime = e[0]
        this.editRoomForm.endTime = e[1]
      }
    },
    // 跳转房间详情
    handleToDetails (roomId) {
      this.$router.push(
        {
          path: '/pczx_signin_manage',
          query: {
            roomId: roomId
          }
        }
      )
    },
    // 新建考场弹窗关闭
    editRoomDialogClose () {
      this.$refs.editRoomFormRef.resetFields()
      this.editRoomForm = {
        ampm: null,
        code: '',
        name: '',
        type: null,
        dateRange: null,
        state: true,
        sitNum: null,
        season: this.$chnEngStatusCode.defaultSeason
      }
    },
    // 编辑房间点击事件
    editRoom (rowData) {
      console.log('rowData', rowData)
      this.editRoomForm = rowData
      this.editRoomForm.dateRange = [rowData.startTime, rowData.endTime]
      this.editRoomDialogVisible = true
    },
    typeStrFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_gameType) {
        if (Object.hasOwnProperty.call(this.dict_gameType, key)) {
          const element = this.dict_gameType[key]
          if (element.dictValue === data.type) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 保存房间信息
    saveRoomInfo () {
      this.$refs.editRoomFormRef.validate(async valid => {
        if (!valid) return
        createPczxRoom(this.editRoomForm).then((res) => {
          this.editRoomDialogVisible = false
          this.$message.success('保存成功！')
          this.getDataList()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    }
  }
}
</script>

<style>
</style>
